// 商品路由
const name = '概况'
import Layout from '@/view/layout.vue' // 主框架

export default {
  path: '/shopadmin/gaikuo',
  component: Layout,
  redirect: '/shopadmin/gaikuo/index',
  children: [
    {
      path: 'index',
      name: `shopadmin-dashboard`,
      meta: {
        title: `${name}`,
        hidemenu: true
      },
      component: () => import('@/view/base/shopgk')
    },
    {
      path: 'admininfo',
      name: `dashboard`,

      component: () => import('@/view/base/shop/admininfo')
    }
  ]
}
