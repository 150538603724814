import fetch, { axios } from '@/utils/fetch'
import store from '@/store'

export function handleUploadFile(query) {
  return fetch({
    url: '/espier/upload_file',
    method: 'post',
    params: query
  })
}
export function newhandleUploadFile(query) {
  return fetch({
    url: '/espier/upload_agreement',
    method: 'post',
    params: query
  })
}
export function consultation_allocation(query) {
  return fetch({
    url: '/consultation_allocation_item_store',
    method: 'post',
    params: query
  })
}
export function aftersales_chance(query) {
  return fetch({
    url: '/aftersales_chance',
    method: 'post',
    params: query
  })
}
export function newdriver(query) {
  return fetch({
    url: '/order/delivery/driver',
    method: 'post',
    params: query
  })
}
export function agreement(query) {
  return fetch({
    url: '/order/agreement',
    method: 'post',
    params: query
  })
}
export function getUploadLists(query) {
  return fetch({
    url: '/espier/upload_files',
    method: 'get',
    params: query
  })
}

export function exportUploadErrorFile(id, query) {
  return fetch({
    url: '/espier/upload_error_file_export/' + id,
    method: 'get',
    params: query
  })
}
export function trade_ed(query) {
  return fetch({
    url: '/trade_ed',
    method: 'post',
    params: query
  })
}
export function payment_trade_ed(query) {
  return fetch({
    url: '/companys/getBankInfo ',
    method: 'get',
    params: query
  })
}
export function exportUploadTemplate(query) {
  return fetch({
    url: '/espier/upload_template',
    method: 'get',
    params: query
  })
}

export function getBrandLogo() {
  return fetch({
    url: '/companys/setting',
    method: 'get'
  })
}

export function getAddress() {
  return fetch({
    url: '/espier/address',
    method: 'get'
  })
}

export function download() {
  return fetch({
    url: '/espier/upload_template',
    method: 'get',
    params: {
      file_type: 'normal_orders',
      file_name: '批量发货'
    }
  })
}

// 获取文件
export function getFileBlob(url) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
      headers: { 'Authorization': 'bearer ' + store.getters.token }
    })
      .then((data) => {
        resolve(data.data)
      })
      .catch((error) => {
        reject(error.toString())
      })
  })
}
