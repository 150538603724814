import fetch from '../utils/fetch'

// 基础物料
export function getServiceLabelsList(query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'get',
    params: query
  })
}

export function getServiceLabelsDetail(labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'get'
  })
}
export function appendid(query) {
  return fetch({
    url: '/goods/getItemUpdateStatus/',
    method: 'get',
    params: query
  })
}
export function createServiceLabels(query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'post',
    params: query
  })
}

export function updateServiceLabels(labelId, query) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'put',
    params: query
  })
}

export function deleteServiceLabels(labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'delete'
  })
}

// 商品类型
export function getItemsTypeList(query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'get',
    params: query
  })
}
// 提交报价
export function tTServiceLabels(query) {
  return fetch({
    url: '/consultation_allocation',
    method: 'post',
    params: query
  })
}
// 修改报价
export function updatelist(query) {
  return fetch({
    url: '/consultation_allocation_update',
    method: 'post',
    params: query
  })
}
// 放弃报价
export function give_up(query) {
  return fetch({
    url: '/consultation_allocation_updates',
    method: 'post',
    params: query
  })
}
export function getItemsTypeDetail(typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'get'
  })
}

export function getItemsTypeLabelsDetail(typeId) {
  return fetch({
    url: '/goods/itemstypelabels/' + typeId,
    method: 'get'
  })
}

export function createItemsType(query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'post',
    params: query
  })
}

export function updateItemsType(typeId, query) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'put',
    params: query
  })
}

export function deleteItemsType(typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'delete'
  })
}

// 商品
export function getItemsList(query) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: {
      ...query,
      distributor_id: !query.distributor_id ? 0 : query.distributor_id
    }
  })
}
// 报价单列表
export function getBjList(query) {
  return fetch({
    url: '/consultation_allocation',
    method: 'get',
    params: query
  })
}
// 报价单详情
export function getdetailList(id) {
  return fetch({
    url: '/consultation_allocation/' + id,
    method: 'get',
  })
}
// 查看单详情
export function getnewdetailList(id) {
  return fetch({
    url: '/consultation_allocation_next/' + id,
    method: 'get',
  })
}
// 产品选择
export function getxzlList(query) {
  return fetch({
    url: '/h5app/wxapp/goods/items',
    method: 'get',
    params: query
  })
}
// // 获取产品id
export function getcompantid(query) {
  return fetch({
    url: '/consultation_distributor_id',
    method: 'post',
    params: query
  })
}
// // 新增规格
export function add_Specifi_template(query) {
  return fetch({
    url: '/goods/addSpecItem',
    method: 'post',
    params: query
  })
}
export function getSkuList(query) {
  return fetch({
    url: '/goods/sku',
    method: 'get',
    params: query
  })
}

export function getItemsDetail(itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'get'
  })
}

export function createItems(query) {
  return fetch({
    url: '/goods/items',
    method: 'post',
    params: query,
    refreshToken: true
  })
}
export function getupload (amount) {
  return fetch({
    url: '/espier/upload_base64_img',
    method: 'post',
    params: amount
  })
}
export function updateItems(itemId, query) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'put',
    params: query,
    refreshToken: true
  })
}

export function setItemsTemplate(params) {
  return fetch({
    url: '/goods/setItemsTemplate',
    method: 'post',
    params: params
  })
}

export function setItemsSort(params) {
  return fetch({
    url: '/goods/setItemsSort',
    method: 'post',
    params: params
  })
}

export function setItemsCategory(params) {
  return fetch({
    url: '/goods/setItemsCategory',
    method: 'post',
    params: params
  })
}

export function deleteItems(itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'delete'
  })
}

export function getDistributionGoodsWxaCode(query) {
  return fetch({
    url: '/goods/distributionGoodsWxaCodeStream',
    method: 'get',
    params: query
  })
}

export function getItemWarningStore(query) {
  return fetch({
    url: '/goods/warning_store',
    method: 'post',
    params: query
  })
}

export function getCategory(params) {
  return fetch({
    url: '/goods/category',
    method: 'get',
    params
  })
}

export function addCategory(params) {
  return fetch({
    url: '/goods/createcategory',
    method: 'post',
    params: params
  })
}

export function editCategory(params) {
  return fetch({
    url: '/goods/category/' + params.category_id,
    method: 'put',
    params: params
  })
}

export function saveCategory(params) {
  return fetch({
    url: '/goods/category',
    method: 'post',
    params: params
  })
}

export function deleteCategory(category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'delete'
  })
}

export function updateCategory(category_id, query) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'put',
    params: query
  })
}

export function getCategoryInfo(category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'get'
  })
}

export function getGoodsAttr(query) {
  return fetch({
    url: '/goods/attributes',
    method: 'get',
    params: query
  })
}

export function addGoodsAttr(query) {
  return fetch({
    url: '/goods/attributes',
    method: 'post',
    params: query
  })
}

export function updateGoodsAttr(attributes_id, query) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'put',
    params: query
  })
}

export function deleteGoodsAttr(attributes_id) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'delete'
  })
}
export function authenticate(query) {
  return fetch({
    url: '/goods/attributes_authentication',
    method: 'post',
    params: query
  })
}
export function editsort(query) {
  return fetch({
    url: '/goods/attributes_weigh',
    method: 'post',
    params: query
  })
}
export function updateGoodsInfo(query) {
  return fetch({
    url: '/goods/itemsupdate',
    method: 'put',
    params: query
  })
}

export function getGoodsPrice(id) {
  return fetch({
    url: '/goods/memberprice/' + id,
    method: 'get'
  })
}

export function getGoodsProfitPrice(id) {
  return fetch({
    url: '/goods/profit/' + id,
    method: 'get'
  })
}

export function saveGoodsProfitPrice(params) {
  return fetch({
    url: '/goods/profit/save',
    method: 'post',
    params: params
  })
}

export function saveCategoryProfitPrice(params) {
  return fetch({
    url: '/goods/category/profit/save',
    method: 'post',
    params: params
  })
}

export function updateGoodsPrice(query) {
  return fetch({
    url: '/goods/memberprice/save',
    method: 'post',
    params: query
  })
}

export function updateItemRebateConf(query) {
  return fetch({
    url: '/goods/rebateconf',
    method: 'post',
    params: query
  })
}

export function saveTag(query) {
  return fetch({
    url: '/goods/tag',
    method: 'post',
    params: query
  })
}

export function getTagList(query) {
  return fetch({
    url: '/goods/tag',
    method: 'get',
    params: query
  })
}

export function getTagInfo(tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag(query) {
  return fetch({
    url: '/goods/tag',
    method: 'put',
    params: query
  })
}

export function deleteTag(tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'delete'
  })
}

export function itemsRelTags(query) {
  return fetch({
    url: '/goods/reltag',
    method: 'post',
    params: query
  })
}

export function tagSearchItem(query) {
  return fetch({
    url: '/goods/tagsearch',
    method: 'GET',
    params: query
  })
}

export function setHotKeywords(query) {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'post',
    params: query
  })
}

export function getHotKeywords() {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'get'
  })
}

export function updateItemsStore(query) {
  return fetch({
    url: '/goods/itemstoreupdate',
    method: 'put',
    params: query
  })
}

export function updateItemsStatus(query) {
  return fetch({
    url: '/goods/itemstatusupdate',
    method: 'put',
    params: query
  })
}

export function auditItems(query) {
  return fetch({
    url: '/goods/audit/items',
    method: 'put',
    params: query
  })
}

export function getGoodsExport(query) {
  return fetch({
    url: '/goods/export',
    method: 'get',
    params: query
  })
}

export function exportItemsData(data) {
  return fetch({
    url: '/goods/export',
    method: 'post',
    params: data
  })
}

export function exportItemsTagData(data) {
  return fetch({
    url: '/goods/tag/export',
    method: 'post',
    params: data
  })
}

export function syncItems() {
  return fetch({
    url: '/goods/sync/items',
    method: 'post'
  })
}

export function syncMainCategory() {
  return fetch({
    url: '/goods/sync/itemCategory',
    method: 'post'
  })
}

export function syncItemSpec() {
  return fetch({
    url: '/goods/sync/itemSpec',
    method: 'post'
  })
}

export function syncBrand() {
  return fetch({
    url: '/goods/sync/brand',
    method: 'post'
  })
}

export function saveIsGifts(query) {
  return fetch({
    url: '/goods/itemsisgiftupdate',
    method: 'put',
    params: query
  })
}

// 获取商品分享设置
export function getShareSetting(query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'get',
    params: query
  })
}

// 保存商品分享设置
export function saveShareSetting(query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'post',
    params: query
  })
}

// 导出小程序太阳码/h5二维码
export function exportGoodsCode(params) {
  return fetch({
    url: '/goods/code/export',
    method: 'post',
    params
  })
}

// 选品商品
export function getSelectItemsList(query) {
  return fetch({
    url: '/goods/selectItems',
    method: 'get',
    params: query
  })
}
// 确定选品商品
export function flowItems(query) {
  return fetch({
    url: '/goods/flowItems',
    method: 'post',
    params: query
  })
}
//一键导入设置规格参数
export function specnew_Price(query) {
  return fetch({
    url: '/goods/importItemsTemplateGoods',
    method: 'post',
    data: query
  })
}
//商品里设置规格参数
export function specPrice(query) {
  return fetch({
    url: '/goods/template_goods',
    method: 'post',
    data: query
  })
}
//规格模板================================================
//列表
export function specifications(query) {
  return fetch({
    url: '/goods/template',
    method: 'get',
    params: query
  })
}
//新增
export function addSpecifications(data) {
  return fetch({
    url: '/goods/template',
    method: 'post',
    data
  })
}
//更新 
export function updateSpecifications(data) {
  return fetch({
    url: '/goods/template_ed',
    method: 'post',
    data
  })
}
//删除 
export function deleteSpecifications(id) {
  return fetch({
    url: `/goods/template/${id}`,
    method: 'delete',
  })
}
//参数模板
//列表
export function getparameter(query) {
  return fetch({
    url: '/goods/parameter',
    method: 'get',
    params: query
  })
}
//新增
export function addparameter(data) {
  return fetch({
    url: '/goods/parameter',
    method: 'post',
    data
  })
}
//更新

export function updateparameter(data) {
  return fetch({
    url: '/goods/parameter_ed',
    method: 'post',
    data
  })
}
//删除
export function deleteparameter(id) {
  return fetch({
    url: `/goods/parameter/${id}`,
    method: 'delete',
  })
}
//新增类目
export function add_cate(data) {
  return fetch({
    url: '/goods/ncreatecategory',
    method: 'post',
    data
  })
}


