import fetch from '@/utils/fetch'

export function getBrandLogo () {
  return fetch({
    url: '/companys/setting',
    method: 'get'
  })
}
//未读信息
export function getNewMessage () {
  return fetch({
    url: '/companys/getNewMessage',
    method: 'get'
  })
}
// 修改密码
export function editpassword(data) {
  return fetch({
    url: '/account/changePasswordOperator',
    method: 'PATCH',
    params: data
  })
}
//获取分享设置
export function getShareParams () {
  return fetch({
    url: '/shareParameters/setting',
    method: 'get'
  })
}

//设置分享设置
export function saveShareParams (data) {
  return fetch({
    url: '/shareParameters/setting',
    method: 'post',
    params: data
  })
}


//获取商品列表
export function getItemList (query) {
  return fetch({
    url: '/auto/getItemList',
    method: 'get',
    params: query
  })
}
//保存标签
export function saveItmeTags (data) {
  return fetch({
    url: '/auto/saveItmeTags',
    method: 'post',
    params: data
  })
}

//获取标签
export function getTagsList (query) {
  return fetch({
    url: '/auto/getTagsList',
    method: 'get',
    params: query
  })
}