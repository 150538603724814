<template>
    <div>
        <tiny-editor v-model="content" :init="init" id="textarea" @onClick="onClick"></tiny-editor>
        <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :is-most="true" @chooseImg="pickThumb"
            @closeImgDialog="closeThumbDialog" />

        <el-dialog title="赋值" :visible.sync="showEdit" width="600px" :destroy-on-close="true" :lock-scroll="false"
            :append-to-body="true">
            <el-form :model="form" ref="editForm" label-width="120px" :inline="true">
                <el-form-item label="所属模块字段：" prop="field">
                    <el-select v-model="form.field" style="width: 130px" filterable placeholder="选择字段">
                        <el-option v-for="(item, index) in fieldslist" :key="index" :label="item.name"
                            :value="item.fields">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="showEdit = false">取消</el-button>
                <el-button type="primary" @click="save">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
// import tinymce from 'tinymce/tinymce'
import editor from '@tinymce/tinymce-vue'
import imgPicker from '@/components/imageselect'
export default {
    name: 'myEditor',
    components: {
        imgPicker,
        'tiny-editor': editor
    },
    props: {
        Richvalue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form: {}, // 表单数据
            fieldslist: [
                {
                    name: '商品详情',
                    fields: 'goods_desc'
                },
                {
                    name: '商品参数',
                    fields: 'goods_param'
                }
            ],
            thumbDialog: false,
            isGetThumb: false,
            showEdit: false,
            content: '',
            init: {
                // 配置信息
                selector: '#textarea',
                height: 550,
                // language_url: './zh_CN.js', // 中文包的存放路径
                language: 'zh_CN',
                content_css: 'static/js/tinymce/skins/content/dark/content.min.css',
                fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px' ,
                font_size_input_default_unit: "px",
                toolbar_mode: 'none',
                plugins: 'textcolor  lists table',
                toolbar:
                    'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo |    code lineheight | removeformat |customLinkButton |customBtn |assignment',
                // menubar: false,
                menubar: 'edit insert format table',
                branding: false,
                menu: {
                    //   insert: { title: "Insert", items: " image table " },

                    // format: {
                    //     title: 'Format',
                    //     items:
                    //         'bold italic underline strikethrough superscript subscript | formats   | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent |   removeformat |code lineheight '
                    // }
                },
                setup: (editor) => {
                    const that = this
                    that.myEditor = editor
                    //定义一个名为 assignment 的toolbar


                    editor.ui.registry.addButton('customBtn', {
                        icon: 'image', // 显示的图标
                        tooltip: '插入图片', // 提示文字
                        onAction: function () {
                            console.log(that, 'this')
                            that.thumbDialog = true
                            that.isGetThumb = true
                        }
                    })
                    editor.ui.registry.addButton('assignment', {
                        text: `<i class="el-icon-user-solid" style="font-size: 18px"></i>`,
                        tooltip: '客服按钮',
                        onAction: () => {
                            // that.showEdit = true

                            this.$confirm('是否添加客服按钮?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                const link = 'kefuurl'
                                const imgUrl = 'http://ecshopx-api-prod.eos-beijing-2.cmecloud.cn/default_project/image/png/1/2024/01/16/4RZtiBe92bfA6QBWxvmLDsshmYgYzIx4RZGkK2PX.png'
                                //a标签增加样式
                                // _this.mold_field = '<a href="' + link + '" style="color:red;">'+'<img src="' + imgUrl + '" />' + '联系卖家</a>'
                                //超链接
                                //超链接
                                that.mold_field = '<a href="' + link + '" target="_blank" kefuurl="true" class="kefuurl" style="color:#2A84E9; display:flex; align-items: center;  text-decoration: none;font-size:14px;">' + '<img src="' + imgUrl + '" style="margin-right:4px;width:15px;height:14px;" />' + '联系卖家</a>'
                                that.myEditor.insertContent(that.mold_field)
                            }).catch(() => {

                            });
                        },
                    })

                    // 初次化编辑器
                    editor.on('init', () => {
                        editor.setContent(this.Richvalue)
                    })
                },
                // urlconverter_callback: (url, node, on_save, name) => {
                //     console.log(url, 'url')
                //     // const file = {
                //     //     base64_img: url
                //     // }
                //     // console.log(file, 'file')
                //     // getupload(file).then(((res) => {
                //     //         // loading.close();
                //     //         console.log(res.data.data.url, 'res')
                //     //         // this.editor.insertContent('<img src="' + res.data.data.url + '" />');
                //     //     }))
                // },
                init_instance_callback: (editor) => {
                    this.editor = editor
                },
                paste_data_images: true,
                automatic_uploads: true
            }
        }
    },
    mounted() {
        // this.content = this.Richvalue
    },
    watch: {
        content(newValue) {
            console.log(newValue, 'newValue 100')
            this.$emit('input', newValue)
        },
        Richvalue: {
            handler() {
                this.content = this.Richvalue
            },
            immediate: true //立即执行
        }
    },
    created() { },

    methods: {
        onClick(e) {
            this.$emit('onClick', e)
        },
        // clear() {
        //     this.content = ''
        // },
        pickThumb(arr) {
            console.log(arr, 'imgs')
            if (Array.isArray(arr) && arr.length > 0) {
                arr.forEach((data) => {
                    if (data && data.url !== '') {
                        this.thumbDialog = false
                        this.editor.insertContent('<img src="' + data.url + '" />')
                        console.log(this.editor, 'insert content')
                    }
                })
            }

            //   this.$emit('input', this.editor.getContent())
        },
        save() {
            let _this = this
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    _this.showEdit = false
                    const link = 'https://www.baidu.com/?tn=62095104_7_oem_dg'
                    const imgUrl = 'http://ecshopx-api-prod.eos-beijing-2.cmecloud.cn/default_project/image/png/1/2024/01/16/4RZtiBe92bfA6QBWxvmLDsshmYgYzIx4RZGkK2PX.png'
                    //a标签增加样式
                    // _this.mold_field = '<a href="' + link + '" style="color:red;">'+'<img src="' + imgUrl + '" />' + '联系卖家</a>'
                    //超链接
                    //超链接
                    _this.mold_field = '<a href="' + link + '" style="color:#2A84E9; display:flex;align-items: center;  text-decoration: none;">' + '<img src="' + imgUrl + '" style="margin-right:4px;" />' + '联系卖家</a>'
                    console.log(_this.mold_field)
                    _this.myEditor.insertContent(_this.mold_field)
                } else {
                    return false
                }
            })
        },
        closeThumbDialog: function () {
            this.thumbDialog = false
        }
    }
}
</script>