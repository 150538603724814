<template>
  <el-container class="page-layout">
    <!--左侧菜单开始-->
    <el-aside class="aside">
      <div class="menu-warp view-flex">
        <div class="main-menu">
          <div class="brand-con">
            <div v-if="login_type === 'merchant'">
              <div class="img-wrap">
                <img :src="brandIco" alt="" />
              </div>
            </div>
            <router-link v-else class="brand-link"
              :to="`${login_type == 'distributor' || login_type == 'dealer' ? dynamicURL : '/'}`">
              <div class="img-wrap">
                <img :src="sys_logo" alt="" />
              </div>
            </router-link>
          </div>
          <div class="main-menu__con">
            <el-menu text-color="#fff" :default-active="activeIndex" @select="handleSelectMenu">
              <el-menu-item v-for="(item, index) in menuList" :key="`menu-item__${index}`" :index="item.url">
                <router-link :to="getMenuUrl(item)">
                  <el-badge :value="item.number" class="buy_item">
                    <i class="iconfont" :class="`icon-${item.icon}`" />
                    <span>{{ item.name }}</span>
                  </el-badge>
                </router-link>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div v-if="$route.meta && !$route.meta.hidemenu" class="sub-menu">
          <el-menu :default-active="activeSubIndex">
            <template v-for="(child, cindex) in submenuList">
              <el-submenu v-if="child.children && child.children[0].is_menu" :key="`cmenu-${cindex}`" :index="`${cindex}`"
                class="menu-group">
                <template slot="title">
                  <i class="iconfont icon-xiala" />{{ child.name }}
                </template>
                <template v-for="sub in child.children">
                  <!-- {{ sub.url }} -->

                  <div :key="sub.url" class="menu-three">
                    <el-menu-item v-if="sub.is_show && sub.is_menu" :index="sub.url"
                      :class="{ 'is-active': sub.url == activeSubIndex }">
                      <router-link :to="sub.url">
                        {{ sub.name }}
                        <!-- {{ sub.url }} 
                      -->
                      </router-link>
                    </el-menu-item>
                  </div>
                </template>
              </el-submenu>
              <div v-else-if="child.is_show && child.is_menu" :key="`cmenu-${cindex}`" :index="cindex"
                class="sub-menu-item">
                <el-menu-item :index="child.url" :class="{ 'is-active': child.url == activeSubIndex }">
                  <router-link :to="child.url">
                    {{ child.name }}
                  </router-link>
                </el-menu-item>
              </div>
            </template>
          </el-menu>
        </div>
      </div>
    </el-aside>

    <el-container class="app-container">
      <el-header v-if="isShowHeader()" class="header" height="48px">
        <div class="header-left">
          <div v-if="operator_type == 'distributor'" class="shop_title">工业商城 - 卖家端</div>
          <!-- <popover  :msg="msg"></popover> -->
          <!-- activeIndex: {{activeIndex}}
          activeSubIndex: {{activeSubIndex}} -->
          <!-- <div class="description">
            <div class="description_title">
              <i class="el-icon-question" style="font-size: 18px; color: #1588F0;"></i>
              <span class="sm_title">合同流程说明</span>
            </div>
            <div class="description_title">
              <span class="reminder">不再提醒</span>
              <i class="el-icon-error" style="font-size: 18px"></i>
            </div>
          </div> -->
        </div>
        <div class="header-right">
          <div @click="goconvex_seller" v-if="seller_msg">
            <el-badge :value="seller_no_read" class="msg_titile ">
              <span class="help_titlep">卖家客服</span>
            </el-badge>
          </div>
          <div class="icon-nav" @click="goconvex_msg">
            <el-badge :value="manage_msg" class="msg_titile">
              <span class="help_title">消息</span>
            </el-badge>
          </div>
          <div class="icon-nav" @click="goflow">
              <span class="help_title">订单流程</span>
          </div>
          <div class="home_at" @click="bank">
            <img src="../assets/img/homeq.png" class="home_pp"></img>
            <div class="go_bank">返回主页</div>
          </div>
          <div class="user-setting">
            <el-popover v-model="showUserPopover" placement="bottom" trigger="hover" popper-class="logout-popper">
              <div class="popover-row base" @click="handleUserInfo" style="padding: 0 20px;margin-bottom:0px;">
                <div class="avatar" style="margin:10px 0 10px 0;">
                  <img v-if="avatar" class="user-avatarp" :src="avatar" />
                  <i v-else class="user-avatarp iconfont icon-user-circle1" style="font-size: 48px;" />
                </div>
                <div class="username">
                  <div>{{ manage_name }}</div>
                  <!-- <small class="muted">账号信息</small> -->
                </div>
                <div class="username_compy" style="margin-bottom:10px;">
                  {{ manage_company }}
                </div>
              </div>
              <div class="popover-row change_password " @click="edit_password" v-if="operator_type == 'staff'">修改密码</div>

              <div class="popover-row exit-system" @click="logout" v-if="operator_type == 'admin' || operator_type == 'staff'" >退出登录</div>

              <img v-if="avatar" slot="reference" class="user-avatar" :src="avatar" />
              <i v-else slot="reference" class="iconfont icon-user-circle1" />
            </el-popover>
          </div>
        </div>
      </el-header>
      <el-main style="position: relative; background: #f0f2f5">
        <router-view class="content-container" :class="{ 'footer-fixed': $route.meta && $route.meta.footerFixed }" />
        <div class="system-release">
          <img v-if="VERSION_B2C" :src="loginBottomB2c" alt="" class="system-image" />
          <img v-if="VERSION_IN_PURCHASE" :src="loginBottomInpurchase" alt="" class="system-image" />
          <img v-if="VERSION_PLATFORM" :src="loginBottomPlatform" alt="" class="system-image" />
          <img v-if="VERSION_STANDARD" :src="loginBottomStandard" alt="" class="system-image" />
          <!-- {{ VUE_APP_SYSTEM_NAME }} -->
        </div>
        <div id="design-view" />
        <!-- <el-popover popper-class="tag-popover" placement="left-start" trigger="click">
          <div class="wx_account">
            微信公众号
          </div>
          <img src="../assets/img/wx_tip.png" alt="" class="wx_bg">
          <img src="../assets/img/server_buy.png" class="serve" slot="reference">
        </el-popover> -->
        <img src="../assets/img/pt_serve.png" class="serve" @click="go_server">

      </el-main>
    </el-container>
    <el-dialog title="编辑账号信息" :visible.sync="editVisible">
      <template>
        <el-form class="demo-ruleForm" :model="formData" label-width="120px" :rules="rule" ref="formData">
          <el-form-item label="登录账号">
            <el-col :span="10">
              <el-input v-model="manage_name" :disabled="true" />
            </el-col>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-col :span="10">
              <el-input v-model="formData.password" :maxlength="255" />
            </el-col>
          </el-form-item>
        </el-form>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editVipass">
          取消
        </el-button>
        <el-button type="primary" @click="submitAction()">
          保存
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
const loginBottomB2c = require('@/assets/logo_bottom/b2c.png')
const loginBottomInpurchase = require('@/assets/logo_bottom/in_purchase.png')
const loginBottomPlatform = require('@/assets/logo_bottom/platform.png')
const loginBottomStandard = require('@/assets/logo_bottom/standard.png')
import Cookies from 'js-cookie'
import { requiredRules, MinRules } from '@/utils/validate'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getAuthorizelogout } from '@/api/login'
import { VERSION_STANDARD, isInSalesCenter } from '@/utils'
import { micrApp } from '@/utils/micr-app'
import store from '@/store'
import popover from './description'
export default {
  components: {
    popover
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.activeIndex = to.matched[0].path || '/'
      console.log(
        'beforeRouteEnter',
        to.matched[0].path,
        vm.$route.matched[0].path || '/',
        vm.activeIndex
      )
    })
  },
  beforeRouteUpdate(to, from, next) {
    console.log(this.submitlist, 'submitlist')
    this.submenuList?.forEach((menu, index) => {
      if (typeof menu.children == 'undefined') {
        if (menu.url == to.path) {
          this.menuOpeneds = [`${index}`]
        }
      } else {
        if (menu.children.find((item) => item.url == to.path)) {
          this.menuOpeneds = [`${index}`]
        }
      }
    })
    next()
  },
  data() {
    return {
      tp: 20,
      editVisible: false,
      msg: '合同流程说明',
      isShowAside: 'false',
      findMenu: false,
      menusList: [],
      formData: {
        password: ''
      },
      rule: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      },
      homeIndex: '',
      brandIco: '',
      showUserPopover: false,
      activeIndex: '',
      menuOpeneds: [],
      loginBottomB2c,
      loginBottomInpurchase,
      loginBottomPlatform,
      loginBottomStandard,
      manage_name: '',
      manage_company: '',
      manage_msg: '',
      operator_type: '',
      seller_msg: '',
      seller_no_read: '',
      chat_win_url: '',
      timer: null
    }
  },
  computed: {
    dynamicURL() {
      // return ''
      console.log(this.$store.getters.menus, '222')
      // return '/'
      return this.$store.getters.menus[0]?.children
        ? this.$store.getters.menus[0]?.children[0]?.url
        : ''
    },
    ...mapGetters([
      'name',
      'nick_name',
      'avatar',
      'wxapp_id',
      'template_name',
      'login_type',
      'sys_logo'
    ]),
    ...mapState({
      menuList: (state) => {
        const { menus } = state.menu
        if (store.getters.login_type == 'distributor') {
          menus.forEach((menu) => {
            const paths = menu.url.match(/\/\w+/g)
            menu.url = `${paths[0]}${paths[1]}`
          })
        }
        return menus
      }
    }),
    submenuList() {
      let list = []
      const fd = this.menuList.find((item) => {
        // const paths = item.url.match(/\/[a-z]+/g)
        const paths = item.url.match(/\/\w+/g)
        if ((paths && paths[0] == '/shopadmin') || (paths && paths[0] == '/dealer')) {
          // return `${paths[0]}${paths[1]}` == this.activeIndex
          return `${paths[0]}${paths[1]}` == this.$route.matched[0].path
        } else {
          // return item.url == this.activeIndex
          return item.url == this.$route.matched[0].path
        }
      })
      return fd ? fd.children : []
    },
    activeSubIndex() {
      return this.$route.matched.length > 1 ? this.$route.matched[1].path : ''
    }
  },
  created() {
    // console.log(this.$route.query.callback_url, '9999',JSON.parse(window.localStorage.getItem("__presist")).user.token)
    //cookie中的token 与localstorage 中不同时，重新登录
    let token = this.$cookies.get('ecshop_admin_token_value')
    if (token != JSON.parse(window.localStorage.getItem('__presist')).user.token) {
      if (token) {
        let callback_url = this.$route.query.callback_url || 'shoplist'
        this.relogin(callback_url)
      }
    }
    this.timer = setInterval(() => {
      this.getNewMessageSetting()
    }, 3000)
    // this.getSystemSetting()
  },
  beforeDestroy() {
    if (this.timer) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.timer) //关闭
    }
  },
  mounted() {
    this.getSystemSetting()
    micrApp.init()

    // console.log('login_type', this.login_type)
    // console.log(JSON.parse(window.localStorage.getItem("__presist")).user.token, '56565566')
    // console.log(this.$route, this.menuList, '----this.menuList----')
    // console.log(this.VUE_APP_PRODUCT_MODEL, '----version----')
  },

  methods: {
    goflow() {
      const { href } = this.$router.resolve({ name: 'flow' })
      window.open(href, '_blank')
    },
    editVipass() {
      this.formData.password = ''
      this.editVisible = false
    },
    edit_password() {
      this.editVisible = true
    },
    submitAction() {
      this.$api.system.editpassword(this.formData).then((res) => {
        this.editVisible = false
        this.$message({ type: 'success', message: '修改成功' })
      })
    },
    async relogin(callback_url) {
      const loginType = this.login_type
      await this.$api.login.getAuthorizelogout()

      await this.SYSTEM_EXIT()
      if (loginType == 'distributor') {
        window.location.href = `/shopadmin/login` + `?callback_url` + callback_url
      } else if (loginType == 'dealer') {
        window.location.href = `/dealer/login` + `?callback_url` + callback_url
      } else if (loginType == 'merchant') {
        window.location.href = `/merchant/login` + `?callback_url` + callback_url
      } else {
        window.location.href = `/login` + `?callback_url` + callback_url
      }
    },
    flushCom(item) {
      if (item.alias_name == 'shoptradenormalorders') {
        location.reload()
      }
    },
    bank() {
      window.open(process.env.VUE_APP_oind_API)
    },
    goconvex_msg() {
      window.open(process.env.VUE_APP_oind_API + 'index/message/center', '_blank')
    },
    goconvex_seller() {
      window.open(this.seller_msg, '_blank')
    },
    go_server() {
      window.open(this.chat_win_url, '_blank')
    },
    ...mapMutations([
      'SYSTEM_EXIT',
      'SET_TOKEN',
      'SET_TOKEN_EXP',
      'SET_USERINFO',
      'SET_LOGIN_TYPE',
      'SET_VERSION_MODE'
    ]),
    isShowHeader() {
      return !isInSalesCenter()
    },
    handleSelectMenu(key) {
      const paths = key.match(/\/\w+/g)
      if ((paths && paths[0] == '/shopadmin') || (paths && paths[0] == '/dealer')) {
        this.activeIndex = `${paths[0]}${paths[1]}`
      } else {
        this.activeIndex = key
      }
      // const activeMenu = this.menuList.find(item => item.url == key)
      // const getUrl = this.getMenuUrl(activeMenu)
      // this.activeSubIndex = getUrl
    },

    // 获取系统配置信息
    async getSystemSetting() {
      const {
        logo,
        nickname,
        company,
        operator_type,
        kefu_url,
        chat_win_url,
        no_read,
        no_kefu_read,
        auto_user_id
      } = await this.$api.system.getBrandLogo()
      this.seller_msg = kefu_url
      this.chat_win_url = chat_win_url
      this.manage_name = nickname
      this.operator_type = operator_type
      this.manage_company = company
      this.manage_msg = no_read
      this.seller_no_read = no_kefu_read
      this.auto_user_id = auto_user_id

      this.$socket.emit('login', this.auto_user_id)
      this.sockets.listener.subscribe('new_msg', (data) => {
        if(data){
          this.$notify({
          title: '卖家消息',
          dangerouslyUseHTMLString: true,
          message: data.message_body
        });
        }
        console.log(data,'connect连接成功')
      })
      if (logo) {
        this.brandIco = logo
      } else {
        if (this.VERSION_STANDARD) {
          this.brandIco = require(`@/assets/logo/logo_standard.png`)
        } else if (this.VERSION_B2C) {
          this.brandIco = require(`@/assets/logo/logo_b2c.png`)
        } else if (this.VERSION_IN_PURCHASE) {
          this.brandIco = require(`@/assets/logo/logo_inpurchase.png`)
        } else if (this.VERSION_PLATFORM && this.VUE_APP_FREE) {
          this.brandIco = require(`@/assets/logo/logo_free-ecshopx.png`)
        } else if (this.VERSION_PLATFORM && !this.VUE_APP_FREE) {
          this.brandIco = require(`@/assets/logo/logo_ecshopx.png`)
        }
      }
      this.$store.dispatch('setSysLogo', this.brandIco)
    },
    //获取未读信息
    async getNewMessageSetting() {
      const { no_read, no_kefu_read } = await this.$api.system.getNewMessage()
      this.manage_msg = no_read
      this.seller_no_read = no_kefu_read
    },
    // 获取菜单url
    getMenuUrl(item) {
      let url = ''
      if (item.children) {
        if (item.children[0].children) {
          url = item.children[0].children[0].url
        } else {
          url = item.children[0].url
        }
      } else {
        url = item.url
      }
      return url
    },
    handleClickSelectShop() {
      this.$router.push({
        path: '/shopadmin/shoplist'
      })
    },
    handleUserInfo() {
      // debugger
      // console.log(this.matchInternalRoute('admininfo'))
      // // debugger
      // this.$router.push({
      //   path: '/admininfo'
      // })
      // this.$router.push({
      //   path: this.matchInternalRoute('admininfo')
      // })
    },
    // 退出登录
    async logout() {
      const loginType = this.login_type
      await this.$api.login.getAuthorizelogout()
      await this.SYSTEM_EXIT()
      //清除cookie
      // localStorage.removeItem('ecshop_admin_token_value')
      Cookies.remove('ecshop_admin_token_value', {
        path: '/',
        domain: process.env.VUE_APP_token_API
      })
      if (loginType == 'distributor') {
        window.location.href = `/shopadmin/login`
      } else if (loginType == 'dealer') {
        window.location.href = `/dealer/login`
      } else if (loginType == 'merchant') {
        window.location.href = `/merchant/login`
      } else {
        window.location.href = `/login`
      }
    }
  }
}
</script>
<style>
.tag-popover {
  padding: 0 !important;
  min-width: 0 !important;
  width: 122px !important;
  height: 161px;
  background: #ffffff;
  border: 1px solid #ebeef5;
}
</style>
<style scoped lang="scss">
::v-deep .buy_item .el-badge__content {
  border: none !important;
  top: 12px !important;
  right: 3px !important;
  padding: 2px !important;
  background: #ed2920 !important;
  width: 20px !important;
  height: 18px !important;
  line-height: 18px !important;
}

::v-deep .msg_titile .el-badge__content {
  border: none !important;
  top: 2px !important;
  right: 30px !important;
  padding: 2px !important;
  background: #ed2920 !important;
  width: 20px !important;
  height: 18px !important;
  line-height: 18px !important;
}

.icon-buy {
  padding: 10px;
  background: #1588f0;
}

.logout-popper {
}

.home_pp {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.go_bank {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 10px;
}

.home_at {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.page-layout {
  height: 100%;
}

.aside {
  z-index: 22;
  width: auto !important;
}

.menu-warp {
  height: 100%;
}

.brand-con {
  margin-top: 10px;

  .brand-link {
    display: block;
  }

  .img-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.main-menu {
  width: 100px;
  background: #353439;
  position: relative;

  &__con {
    position: absolute;
    top: 72px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  }

  .el-menu {
    background: #353439;
    border-right-width: 0;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px !important;

    &.is-active {
      a {
        color: $color-primary;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
      }

      .iconfont {
        color: $color-primary;
      }
    }

    &:hover {
      background: transparent;

      a {
        color: $color-primary;
      }

      .iconfont {
        color: $color-primary;
      }
    }

    > a {
      display: block;
      color: #fff;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .iconfont {
      color: #fff;
      font-size: 15px;
      margin-right: 6px;
    }
  }
}

.sub-menu {
  width: 130px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.06);

  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  // .menu-group {
  //   margin-bottom: 20px;
  // }
  .el-menu {
    background: #fff;
    border-right-width: 0;
    margin-top: 8px;
  }

  .el-menu-item {
    height: 40px;
    line-height: 40px;
    padding: 0 8px !important;
    display: flex;
    align-items: center;

    &.is-active {
      > a {
        // background: rgba(20, 128, 227, 0.1);
        background: rgba(var(--themeColorRgb), 0.1);
        border-radius: 2px;
        color: $color-primary;
      }
    }

    &:hover {
      background: transparent;

      a {
        color: $color-primary;
      }
    }

    a {
      display: block;
      color: #545d7a;
      // flex: 1;
      // display: flex;
      // padding-left: 24px;
      padding: 0 4px;
    }

    i {
      color: $dominant_hue;
    }
  }
}

.sub-menu-item {
  margin-left: 14px;
}

.header {
  display: flex;
  justify-content: space-between;

  &-right {
    display: flex;
    align-items: center;
    height: 100%;

    .icon-store-alt {
      font-size: 24px;
      margin-right: 20px;
    }

    .icon-user-circle1 {
      font-size: 28px;
    }
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  .user-avatarp {
    width: 45px;
    height: 45px;
    border-radius: 20px;
  }
}

.serve {
  position: fixed;
  right: 0;
  bottom: 104px;
  width: 42px;
  height: 122px;
  cursor: pointer;
  z-index: 1000;
}

.wx_bg {
  width: 100px;
  height: 100px;
  margin: 10px;
}

.wx_account {
  width: 100%;
  height: 31px;
  background: #f8f9fe;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #ebeef5;
  text-align: center;
  line-height: 31px;
  font-size: 14px;
  font-weight: 400;
  color: #9eadc3;
}

.el-main {
  padding: 16px;

  .content-container {
    background-color: #fff;
    padding: 16px;
    border-radius: 0;
    min-height: calc(100vh - 70px);

    &.footer-fixed {
      margin-bottom: 57px;
    }
  }
}

.menu-three {
  margin-left: 10px;

  .el-menu-item {
    min-width: inherit;
  }
}
</style>
<style lang="scss">
.page-layout {
  .el-menu {
    background: #fff;
  }

  .el-submenu {
    &.is-opened {
      .icon-xiala {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
    }

    &__title {
      height: 40px;
      line-height: 40px;
      padding-left: 25px !important;
      display: flex;
      align-items: center;
      position: relative;

      .icon-xiala {
        color: #666;
        font-size: 14px;
        margin-right: 4px;
        position: absolute;
        left: 8px;
        transform: rotate(-90deg);
        transition: all 0.3s ease-in-out;
      }

      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}

.popover-row {
  display: flex;
  flex-direction: column;
}

.popover-row.exit-system {
  font-size: 12px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}

.change_password {
  font-size: 12px;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
  padding-top: 10px;
}

.menu-group {
  .icon-shouqijiantouxiao {
    font-size: 12px;
    color: #3d4355;
    margin-right: 4px;
    transform: scale(0.8);
    display: inline-block;
  }

  .el-menu-item-group__title {
    padding: 0 0 0 10px !important;
    height: 40px;
    line-height: 40px;
    color: #3d4355;
    font-size: 14px;
  }

  > ul {
    margin-left: 14px;
  }
}

.app-container {
  // padding-bottom: 50px;
  .header {
    background-color: #fff;
  }
}

.system-release {
  text-align: center;
  font-size: 16px;
  color: #cdcbcb;
  margin-top: 16px;
}

.system-image {
  height: 22px;
}

.help {
  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.help_title {
  margin-right: 28px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.help_titlep {
  display: block;
  width: 66px;
  height: 22px;
  background: #1588f0;
  border-radius: 11px;
  margin-right: 28px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 22px;
}

.description {
  position: relative;
  z-index: 100;
  width: 288px;
  height: 40px;
  background: #ecf5ff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 16px;
  margin-top: 4px;
}

.description_title {
  display: flex;
  align-items: center;
}

.el-icon-error {
  // margin-right: -34px;
  // margin-top: -26px;
}

.header-left {
  margin-left: 0%;
  line-height: 48px;
}

.shop_title {
  font-size: 14px;
  color: #333333;
}

.sm_title {
  font-size: 12px;
  font-weight: 500;
  color: #1588f0;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;
}

.reminder {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-right: 12px;
  cursor: pointer;
}
</style>
