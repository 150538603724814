<template>
  <div class="description">
    <div class="description_title">
      <i class="el-icon-question" style="font-size: 18px; color: #1588f0"></i>
      <span class="sm_title">{{ msg }} </span>
    </div>
    <div class="description_title">
      <span class="reminder">不再提醒</span>
      <i class="el-icon-error" style="font-size: 18px"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String //字符串
    }
  }
}
</script>

<style>
.description {
  position: relative;
  z-index: 100;
  width: 288px;
  height: 40px;
  background: #ecf5ff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 16px;
  margin-top: 4px;
}
.description_title {
  display: flex;
  align-items: center;
}

.header-left {
  margin-left: 33%;
}
.sm_title {
  font-size: 12px;
  font-weight: 500;
  color: #1588f0;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;
}
.reminder {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-right: 12px;
  cursor: pointer;
}
</style>