// 商品路由
const name = '交易'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/shopadmin/order',
  component: Layout,
  children: [
    {
      path: 'quotation',
      name: `报价单`,
      component: () => import('@/view/mall/goods/physical/listquotation'),
      children: [
        {
          path: 'physicalstoreupload',
          name: `新增报价`,
          component: () => import('@/view/mall/goods/physical/addquotation')
        },
        {
          path: 'physicalprofitup/:itemId?',
          name: `报价详情`,
          component: () => import('@/view/mall/goods/physical/detailquotation')
        },
        {
          path: 'new_physicalprofitup/:itemId?',
          name: `报价`,
          component: () => import('@/view/mall/goods/physical/new_detailquotation')
        },
        {
          path: 'reoffer_physicalprofitup/:itemId?',
          name: `重新报价`,
          component: () => import('@/view/mall/goods/physical/reoffer_detailquotation')
        }
      ]

    },
    {
      path: 'tradenormalorders',
      name: `实物订单`,
      component: () => import('@/view/mall/trade/normalorders/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/trade/order/detail/details')
        },
        {
          path: 'process/:itemId?',
          component: () => import('@/view/mall/trade/order/process')
        }
      ]
    },
    {
      path: 'fapiao',
      name: `发票`,
      component: () => import('@/view/mall/fapiao/list'),

         children: [
          {
            path: 'detail/:itemId?',
            component: () => import('@/view/mall/trade/order/detail/newdetails')
          },
      ]
    },
    {
      path: 'permission',
      name: `合同列表`,
      component: () => import('@/view/mall/trade/normalorders/listnew'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/trade/order/detail/details')
        },
      ]

    },

    {
      path: 'tradepayment',
      name: `交易单`,
      component: () => import('@/view/mall/trade/list')
    },
    {
      path: 'aftersaleslist',
      name: `售后列表`,
      component: () => import('@/view/mall/aftersales/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/detail')
        }
      ]
    },
    {
      path: 'aftersalesrefundlist',
      name: `退款单`,
      component: () => import('@/view/mall/aftersales/refundList'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/refundDetail')
        }
      ]
    },
    {
      path: 'contract',
      name: `合同模板`,
      component: () => import('@/view/mall/aftersales/contract'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/refundDetail')
        }
      ]
    },
    {
      path: 'chinaumsdivisionlist',
      name: `分账单`,
      component: () => import('@/view/mall/chinaums/divisionList')
    },
    {
      path: 'shippingtemplates',
      name: `运费模板`,
      component: () => import('@/view/mall/trade/shipping/templates'),
      children: [
        {
          path: 'editor/:templatesId?',
          component: () => import('@/view/mall/trade/shipping/add')
        }
      ]
    },
    {
      path: 'logistics',
      name: `物流公司`,
      component: () => import('@/view/mall/trade/logistics/normal')
    },
    {
      path: 'adapay_trades',
      name: '分账',
      component: () => import('@/view/mall/sub_account/sub_account'),
      children: [
        {
          path: 'detail/:order_id',
          component: () => import('@/view/mall/sub_account/detail')
        }
      ]
    },
    {
      path: 'adapay_cash',
      name: '提现',
      component: () => import('@/view/mall/withdraw/withdraw')
    },
    {
      path: 'subdistrict',
      name: '街道社区配置',
      component: () => import('@/view/mall/subdistrict/list')
    }
  ]
}
