// 商品路由
const name = '大宗'
import Layout from '@/view/layout.vue' // 主框架

export default {
  path: '/shopadmin/dazong',
  component: Layout,
  redirect: '/shopadmin/dazong/index',
  children: [
    {
      path: 'index',
      name: `shopadmin-dashboard`,
      meta: {
        title: `${name}`,
        hidemenu: true
      },
      component: () => import('@/view/member/groups/index'),
      children: [
        {
          path: 'editor/:groups_id?',
          meta: {
            title: `${name}`,
            hidemenu: true
          },
          component: () => import('@/view/member/groups/add')
        },
        {
          path: 'detail/:team_id?',
          meta: {
            title: `${name}`,
            hidemenu: true
          },
          component: () => import('@/view/member/groups/teamDetail')
        },
        {
          path: 'team/:groups_id?',
          meta: {
            title: `${name}`,
            hidemenu: true
          },
          component: () => import('@/view/member/groups/team')
        }
      ]
    },
  ]
}